import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import i18n from 'i18n';

import Contact from 'pages/contact';
import Home from 'pages/Home';

const App = () => {
  const { lang } = useTranslation();

  return (
    <I18nextProvider lang={lang} i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path="/:lng" element={<Home />} />
          <Route path="/" element={<Home />} />

          <Route path="/:lng/contact" element={<Contact />} />
          <Route path="/contact" element={<Contact />} />

          <Route
            path="/en"
            element={<Navigate to="/" replace />}
          />

          <Route
            path="/en/contact"
            element={<Navigate to="/contact" replace />}
          />

          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
