import React, {
  useState,
  useEffect,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import simpleFetch from 'very-simple-fetch';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Input,
  TextField,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { regExNameAndFemale } from 'constants/validate';

import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import Modals from 'components/modal';

import email from 'img/email.png';

import '../App.scss';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 901,
      lg: 1113,
      xl: 1414,
    },
  },
});

const Contact = () => {
  const key = global.config.recaptcha_site_key;

  const { t: translate, i18n } = useTranslation('contact');

  const { lng } = useParams();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [openModal, setOpenModal] = useState(false);

  useEffect(
    () => {
      if (i18n.language !== lng) {
        if (i18n.language === 'en') {
          navigate(`/contact`);
        } else {
          navigate(`/${i18n.language}/contact`);
        }
      }
    },
    [
      i18n.language,
      lng,
      navigate,
    ],
  );

  const onSubmit = (data) => {
    const response = simpleFetch({
      url:
        global.config.webhook_contact,
        method: 'POST',
        mode: 'no-cors',
        headers: new Headers({ 'Content-Type':'application/json' }),
        body: JSON.stringify(data),
    }).then(() => {
      setOpenModal(true);
      reset({
        fullName: '',
        email: '',
        message: '',
      });
    });

    if (response) {
      console.log(response);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className="container" maxWidth="xl">
        <Header />
      </Container>

      <Box className="contact__box">
        <Box className="contact__banner">
          <h2>
            {translate('headerBanner')}
          </h2>
        </Box>

        <Grid
          className="contact__content"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-top"
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={7}
            sm={12}
          >
            <Box
              className="contact__help-box"
              sx={{
                alignItems: "flex-end",
              }}
            >
              <h3>
                {translate('touchWithUs')}
              </h3>

              <p>
                {translate('description')}
              </p>

              <List
                className="contact__list"
                sx={{
                  width: '100%',
                  background: 'none',
                }}
              >
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    padding: '15px',
                    marginBottom: '30px',
                  }}
                >
                  <ListItemAvatar
                    className="contact__help-avatar"
                    sx={{
                      marginTop: 0,
                    }}
                  >
                    <Avatar alt="email" src={email} />
                  </ListItemAvatar>

                  <ListItemText
                    className="contact__help-text"
                    primary="E-mail"
                    sx={{
                      margin: 0,
                    }}
                    secondary={
                      <React.Fragment>
                        <a
                          className="contact__help-typografy"
                          href="mailto:info@escueladeinversoras.com"
                        >
                          info@escueladeinversoras.com
                        </a>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>

          <Grid
            item
            xl={5}
            lg={6}
            md={5}
            sm={12}
          >
            <GoogleReCaptchaProvider reCaptchaKey={key}>
              <Box
                className="form__contact"
                noValidate
                id="form__contact"
                autoComlete="off"
                component="form" 
                onSubmit={handleSubmit(onSubmit)}
              >
                <h3>
                  PONERSE EN CONTACTO
                </h3>

                <Box sx={{ position: 'relative' }}>
                  <Controller
                    name="fullName"
                    control={control}
                    rules={{
                      required: true,
                      pattern: regExNameAndFemale,
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="input-text"
                        placeholder={translate('namesPlaceholder')}
                      />
                    )}
                  />

                  <p className="error">
                    {errors.fullName?.type === 'required' && translate('requiredField')}
                  </p>

                  <p className="error">
                    {errors.fullName?.type === 'pattern' && translate('invalidCharacters')}
                  </p>
                </Box>

                <Box sx={{ position: 'relative' }}>
                  <PhoneInputWithCountry
                    className="phone__box"
                    international
                    placeholder="Número de teléfono"
                    name="phone"
                    defaultCountry="US"
                    control={control}
                    rules={{
                      required: true,
                      validate: isPossiblePhoneNumber,
                    }}
                  />

                  <p className="error">
                    {errors.phone?.type === 'required' && translate('requiredField')}
                  </p>

                  <p className="error">
                    {errors.phone?.type === 'validate' && translate('phoneIsNoValid')}
                  </p>
                </Box>

                <Box sx={{ position: 'relative' }}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="input-text"
                        placeholder={translate('email')}
                      />
                    )}
                  />

                  <p className="error">
                    {errors.email?.type === 'required' && translate('emailIsRequired')}
                  </p>

                  <p className="error">
                    {errors.email?.type === 'pattern' && translate('emailIsNotValid')}
                  </p>
                </Box>

                <Box sx={{ position: 'relative' }}>
                  <Controller
                    name="message"
                    control={control}
                    rules={{
                      required: true,
                      pattern: /^[^\s]/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="textarea"
                        multiline
                        id="outlined-multiline-static"
                        placeholder={translate('yourMessagePlaceholder')}
                        rows={4}
                      />
                    )}
                  />

                  <p className="error">
                    {errors.message?.type === 'required' && translate('requiredField')}
                  </p>

                  <p className="error">
                    {errors.message?.type === 'pattern' && translate('invalidCharacters')}
                  </p>
                </Box>

                <Box className="bottom">
                  <Button
                    className="btn"
                    variant="contained"
                    type="submit"
                    sx={{ width: '137px'}}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {translate('send')}
                  </Button>
                </Box>
              </Box>
            </GoogleReCaptchaProvider>

            <Modals text={translate('modalSend')} open={openModal} />
          </Grid>
        </Grid>
      </Box>

      <Footer/>
    </ThemeProvider>
  );
}

export default Contact;
