import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../App.scss';
import '@csstools/normalize.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container from '@mui/material/Container';

import { createTheme, ThemeProvider  } from '@mui/material/styles';
import Header from "../components/header/header";
import Banner from "../components/banner/banner";
import Situation from "../components/situation/situation";
import Investor from "../components/investor/investor";
import Why from "../components/why/why";
import About from "../components/about/about";
import Steps from "../components/steps/steps";
import SliderBox from "../components/slider/slider";
import Command from "../components/comand/command";
import Pink from "../components/pink/pink";
import Faq from "../components/faq/faq";
import Logos from "../components/logos/logos";
import Unicorn from "../components/unicorn/unicorn";
import Form from "../components/form/form";
import Footer from "../components/footer/footer";

const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}
const hash = window.location.search;
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 901,
            lg: 1170,
            xl: 1440,
        },
    },
});
function App() {
    const { i18n } = useTranslation();
    const { lng } = useParams();
    const navigate = useNavigate();
  
    useEffect(
      () => {
        if (i18n.language !== lng) {
            if (i18n.language === 'en') {
                navigate(`/${hash}`);
            } else {
                navigate(`/${i18n.language}`);
            }
        }
      },
      [i18n.language, lng, navigate],
    );

    const scroll = ()=>{
        if (hash === '?about'){
            scrollToDiv('about')
        }
        else if(hash === '?command'){
            scrollToDiv('command')
        }
        else if(hash === '?form'){
            scrollToDiv('form')
        }
        else if(hash === '?faq'){
            scrollToDiv('faq')
        }
    }
    window.addEventListener('load', scroll);
  return (
      <ThemeProvider theme={theme}>
          <Container maxWidth="lg" className={'container'}>
              <Header/>
          </Container>
          <Banner/>
          <Situation/>
          <Container maxWidth="lg" className={'container'}>
              <Investor/>
          </Container>
          <Container maxWidth="xl" className={'container-big'}>
            <Why/>
          </Container>
          <About/>
          <Steps/>
          <SliderBox/>
          <Command/>
          <Pink/>
          <Container maxWidth="lg" className={'container'}>
              <Faq/>
          </Container>
          <Logos/>
          <Container maxWidth="lg" className={'container'}>
              <Unicorn/>
          </Container>
          <Form/>
          <Footer/>
      </ThemeProvider>
  );
}

export default App;
