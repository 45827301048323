import * as React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import slider1 from 'img/slider/slider1.png';
import slider2 from 'img/slider/slider2.png';
import slider3 from 'img/slider/slider3.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './slider.scss';

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <Box
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
};

SampleNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <Box
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

const SLIDER_ELEMENTS = [
  {
    href: 'https://youtu.be/G0S7cXd_w34',
    sliderSrc: slider1,
  },
  {
    href: 'https://youtu.be/FznQLLFJ0-I',
    sliderSrc: slider2,
  },
  {
    href: 'https://youtu.be/S8nhVMaRchg',
    sliderSrc: slider3,
  },
];

export default function SliderBox(){
    const settings = {
        className: "center",
        variableWidth: true,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: '60px',
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 600,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    centerMode: true,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                },
            },
        ],
    };

    const { t: translate } = useTranslation('home');

    return(
        <Box className={'slider__box'}>
            <h2>
                {translate('sliderTitle')}
            </h2>

            <Slider {...settings} className="slider__slider center">
              {SLIDER_ELEMENTS.map((element, index) => (
                <Box className="sider__block" key={`slider-${index}`}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={element.href}
                  >
                    <img alt={`slider-${index}`} src={element.sliderSrc} />
                  </a>
                </Box>
              ))}
            </Slider>
        </Box>
    )
};