import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './situation.scss';

export default function Situation(){
    const { t: translate } = useTranslation('home');

    return(
        <Box className={'situation__box'}>
            <Box className={'container'}>
                <h2>{translate('globalSituation')}</h2>

                <Grid container direction="row" className={'situation__grid'}
                      justifyContent="space-between" alignItems="flex-start">
                    <Box className={'situation__item'} >
                        <h4 className={'proc'}>2,3<span>%</span></h4>
                        <p>
                        {translate('firstMetricText')}
                        </p>
                    </Box>
                    <Box className={'situation__item'}>
                        <h4 className={'proc'}>10<span>%</span></h4>
                        <p>
                            {translate('secondMetricText')}
                        </p>
                    </Box>
                    <Box className={'situation__item'}>
                        <h4 className={'proc'}>6<span>%</span></h4>
                        <p>
                            {translate('thirdMetricText')}
                        </p>
                    </Box>
                </Grid>
                <h3>{translate('globalPinkText')}</h3>
                <p>
                    {translate('globalText')}
                </p>
            </Box>
        </Box>
    )
}