import * as React from 'react';
import { useState } from "react";
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import banner from 'img/banner.png';

import './banner.scss'

const scrollToDiv = id => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

export default function Banner(){
    const { t: translate } = useTranslation('home');

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        const applyBtn = document.getElementById('applyBtnNoMobile');
        const form = document.getElementById('form');

        if (form) {
            let offsetTopStart = applyBtn.offsetTop - applyBtn.offsetHeight + 170;
            let offsetTopEnd = form.offsetTop - form.offsetHeight + 400;

            if ( scrolled > offsetTopStart && scrolled < offsetTopEnd ){
                setVisible(true)
            } else {
                setVisible(false)
            }
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return(
        <Box className={'banner__box'}>
            <img src={banner} alt={''}/>

            <Box className={'banner__text'}>
                <h2>
                    {translate('bannerHeader')}
                </h2>

                <p>{translate('bannerSubtitleFirstPart')} <strong>{translate('UH')}</strong>
                    {translate('bannerSubtitleSecondPart')}
                    <strong className={'fs27'}>{translate('bannerStrongDescription')}</strong>
                </p>

                <Box className="bottomBox">
                    <Button
                        className="btn nomobile"
                        id="applyBtnNoMobile"
                        variant="contained"
                        onClick={() => scrollToDiv('form')}
                    >
                        {translate('signUp')}
                    </Button>

                    <Button
                        className="btn mobile"
                        variant="contained"
                        style={{display: visible ? 'inline' : 'none'}}
                        onClick={() => scrollToDiv('form')}
                    >
                        {translate('signUp')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
};