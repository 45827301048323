import * as React from 'react';
import { useTranslation } from 'react-i18next'; 
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './investor.scss';
import investor from '../../img/investor/investor1.svg';
import investor2 from '../../img/investor/investor2.png';
import investor3 from '../../img/investor/investor3.svg';
import investor4 from '../../img/investor/investor4.svg';

export default function Investor() {
    const { t: translate, i18n } = useTranslation('home');

    return (
        <Box className={'investor__box'}>
            <h2>{translate('investorTitle')}</h2>

            <Grid
                container
                direction="row"
                className={'investor__grid'}
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Box className={clsx('investor__item', i18n.language === 'en' && 'investor__item_en')}>
                    <img src={investor} className={'w97'} alt={''}/>
                    <h4>{translate('investorFirst')}</h4>
                    <p>
                        {translate('investorFirstText')}
                    </p>
                </Box>

                <Box className={clsx('investor__item', i18n.language === 'en' && 'investor__item_en')}>
                    <img src={investor2}  alt={''}/>
                    <h4>{translate('investorSecond')}</h4>
                    <p>
                        {translate('investorSecondText')}
                    </p>
                </Box>

                <Box className={clsx('investor__item', i18n.language === 'en' && 'investor__item_en')}>
                    <img src={investor3} alt={''}/>
                    <h4>{translate('investorThird')}</h4>
                    <p>
                        {translate('investorThirdText')}
                    </p>
                </Box>

                <Box className={clsx('investor__item', i18n.language === 'en' && 'investor__item_en')}>
                    <img src={investor4} alt={''}/>
                    <h4>{translate('investorFour')}</h4>
                    <p>
                        {translate('investorFourText')}
                    </p>
                </Box>
            </Grid>
        </Box>
    )
}