import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import { languages } from 'locales';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: ['en', 'es'],
    defaultNS: 'home',
    supportedLngs: ['en', 'es'],
    resources: languages,
    detection: {
      lookupFromPathIndex: 0,
      lookupLocalStorage: 'lng',
      order: ['path', 'localStorage', 'cookie', 'navigator'],
    },
  });

export default i18n;
