import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import './unicorn.scss';
import UnicornImg from '../../img/unicorn.jpg';

export default function Unicorn() {
    const { t: translate } = useTranslation('home');

    return (
        <Box className={'unicorn__box'} id={'sliderLogo'}>
            <h2>Unicorn Hunters</h2>
            <img src={UnicornImg} alt={''}/>
            <Box className={'unicorn__text'}>
                <p>
                    <a href="https://unicornhunters.com/" target="_blank" rel="noreferrer">
                        <strong>Unicorn Hunters</strong>
                    </a>

                    &nbsp;{translate('UHTextFirstPart')}
                    <strong>{translate('steveWozniak')}</strong>
                    {translate('UHTextSecondPart')}
                </p>
                <p><a href="https://unicoin.com/es" target="_blank" rel="noreferrer"><strong>Unicoin</strong></a>
                    &nbsp;{translate('UnicoinText')}
                </p>
            </Box>
        </Box>
    )
}