import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import './why.scss';
import why_bg from '../../img/beneficios-bg.jpg';

export default function Why(){
    const { t: translate } = useTranslation('home');


    return(
        <Box className={'why__box'} id="why">
            <Box className={"why__text"}>
                <h2>{translate('benefits')}</h2>

                <ul>
                    <li><strong>{translate('benefitFirstTitle')}</strong>
                        {translate('benefitFirst')}
                    </li>

                    <li><strong>{translate('benefitSecondTitle')}</strong>
                        {translate('benefitSecond')}
                    </li>

                    <li><strong>{translate('benefitThirdTitle')}</strong>
                        {translate('benefitThird')}
                    </li>

                    <li><strong>{translate('benefitFourTitle')}</strong>
                        {translate('benefitFour')}
                    </li>
                </ul>
            </Box>
            <img src={why_bg} alt=""/>
            <Box className={'why__img'}>&nbsp;</Box>

        </Box>
    )
};