import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import './steps.scss';
import Button from "@mui/material/Button";
import { ReactComponent as Conversation } from '../../img/icon/conversation.svg';
import { ReactComponent as Pen } from '../../img/icon/pen.svg';
import { ReactComponent as Plane } from '../../img/icon/plane.svg';
import { ReactComponent as Tick } from '../../img/icon/tick.svg';

const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

export default function Steps() {
    const { t: translate, i18n } = useTranslation('home');

    return (
        <Box className={'steps__box'}>
            <Box className={'container-big'}>
                <Box className={'steps__text'}>
                    <h2>{translate('stepsJoiningFirstPart')} <strong>
                            {translate('schoolOfInvestors')}
                        </strong> {translate('stepsJoiningSecondPart')}
                    </h2>
                    <Button className={"btn"} onClick={() => scrollToDiv('form')} variant="contained">
                        {translate('signUp')}
                    </Button>
                </Box>

                <Box className={'steps__box-list'}>
                    <ul>
                        <li>
                            <span className={'number'}>01</span>
                            <span className={clsx('text', i18n.language === 'en' && 'text_en')}>
                                {translate('step1')}
                                <Plane />
                            </span>
                        </li>
                        <li>
                            <span className={'number'}>02</span>
                            <span className={clsx('text', i18n.language === 'en' && 'text_en')}>
                                {translate('step2')}
                                <Pen />
                            </span>
                        </li>
                        <li>
                            <span className={'number'}>03</span>
                            <span className={clsx('text', i18n.language === 'en' && 'text_en')}>
                                {translate('step3')}
                                <Conversation />
                            </span>
                        </li>
                        <li>
                            <span className={'number'}>04</span>
                            <span className={clsx('text', i18n.language === 'en' && 'text_en')}>
                                {translate('step4')}
                                <Tick />
                            </span>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    )
}