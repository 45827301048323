import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Select } from '@mui/material';

import './header.scss';

const LANGUAGES = [
  {
    code: 'en',
    name: 'ENGLISH'
  },
  {
    code: 'es',
    name: 'ESPAÑOL',
  },
];

const SelectLanguage = ({
  classes,
  currentLanguage,
  handleChange,
}) => {
  return (
    <Select
      className={`languageSelect ${classes?.select}`}
      value={currentLanguage}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        }
      }}
      onChange={handleChange}
      renderValue={() => currentLanguage.toUpperCase()}
    >
      {LANGUAGES.map((item, index) => (
        <MenuItem
          classes={{
            root: classes?.menu,
          }}
          key={`${item.name}_${index}`}
          value={item.code}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default memo(SelectLanguage);

SelectLanguage.propTypes = {
  classes: PropTypes.shape({
    select: PropTypes.string,
    menu: PropTypes.string,
  }),
  currentLanguage: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}
