import * as React from 'react';
import { useForm} from "react-hook-form";
import simpleFetch from 'very-simple-fetch';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import 'react-phone-number-input/style.css';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {styled} from "@mui/material/styles";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';

import { regExName } from 'constants/validate';

import { ReactComponent as Diamond } from 'img/diamond-form.svg';

import './form.scss';

const steps = ['01', '02'];

const types = [
    "20-30",
    "31-40",
    "41-50",
    "51-60",
    "61-70",
    "+70",
];
const industrys = [
    'yes',
    'no',
];
const areas = [
    'fundFirstSelect',
    'fundSecondSelect',
    'fundThirdSelect',
    'fundFourSelect',
];
const abouts = [
    'yes',
    'no',
];
const years = [
    'yes',
    'no',
];
const savings =[
    'yes',
    'no',
]
const genders =[
    'female',
    'male',
    'nonBinary',
    'otherGender',
    'preferNotSay',
]
const livings =[
    'profession1',
    'profession2',
    'profession3',
    'profession4',
    'profession5',
    'profession6',
]
const experiences = [
    'professionalExperience1',
    'professionalExperience2',
    'professionalExperience3',
    'professionalExperience4',
    'professionalExperience5',
]
const aboutin =[
    'howYouHearAboutUs1',
    'howYouHearAboutUs2',
    'howYouHearAboutUs3',
    'howYouHearAboutUs4',
    'howYouHearAboutUs5',
    'howYouHearAboutUs6',
]

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 22,
    height: 22,
    boxShadow:'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#d1d1d1',
    backgroundImage:'none',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#e27484',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#e27484',
    backgroundImage: 'none',
    '&:before': {
        display: 'block',
        width: 22,
        height: 22,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#e27484',
    },
});

export default function Form(){
    const key = global.config.recaptcha_site_key;

    const { t: translate } = useTranslation('home');

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handlePrev = () => {
        setActiveStep(activeStep - 1);
    };

    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();
    const [personName, setPersonName] = React.useState([]);
    const [personName2, setPersonName2] = React.useState([]);
    const [personName3, setPersonName3] = React.useState([]);
    const [personName4, setPersonName4] = React.useState([]);
    const [personName5, setPersonName5] = React.useState([]);
    const [personName6, setPersonName6] = React.useState([]);
    const [personName7, setPersonName7] = React.useState([]);
    const [personName8, setPersonName8] = React.useState([]);
    const [personName9, setPersonName9] = React.useState([]);
    const [personName10, setPersonName10] = React.useState([]);

    const [checkboxValue, setCheckboxValue] = React.useState(false);

    const handleChangeCheckbox = () => {
        setCheckboxValue(prev => !prev);
        clearErrors("termsAndConditions");
    };

    const handleChange = (event) => {
        setPersonName(event.target.value);
        clearErrors("Gender")
   }
    const handleChange2 = (event) => {
        setPersonName2(event.target.value);
        clearErrors("Age")
    };
    const handleChange3 = (event) => {
        setPersonName3(event.target.value);
        clearErrors("CanYouCommitaTotal")
    };
    const handleChange4 = (event) => {
        setPersonName4(event.target.value);
        clearErrors("HaveAnEmergencyFund")
    };
    const handleChange5 = (event) => {
        setPersonName5(event.target.value);
        clearErrors("HaveSavings")
    };
    const handleChange6 = (event) => {
        setPersonName6(event.target.value);
        clearErrors("HaveYouInvesteds")
    };
    const handleChange7 = (event) => {
        setPersonName7(event.target.value);
        clearErrors("InvestAtTheEndOfTheProgram")
    };

    const handleChange8 = (event) => {
        setPersonName8(event.target.value);
        clearErrors("WhatDoYouDoForALiving")
    };

    const handleChange9 = (event) => {
        setPersonName9(event.target.value);
        clearErrors("YearsOfProfessionalExperience")
    };

    const handleChange10 = (event) => {
        setPersonName10(event.target.value);
        clearErrors("YouFindOutAboutThisCall")
    };
    
    const onSubmit = (data) => {
        console.log(data);
        handleNext();
    };

    const onSubmit_end = async (data) => {
        simpleFetch({
            url: global.config.webhook_main,
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers({'Content-Type':'application/json'}),
            body: JSON.stringify(data),
        }).then((res) => {
            handleNext();
        }).catch((error) => {
                console.log('error:');
                console.log(error);
            });
    };

    return(
        <Box className={'form__box'} id={'form'}>
            <Box className={'container'}>
                <Box className={'left'}>
                    <Diamond width="89"/>
                    <h2>{translate('openCallForCohort')} <br /></h2>
                </Box>

                <Box className={'form__block-form'}>
                    <GoogleReCaptchaProvider reCaptchaKey={key}>
                        <Box component="form"  className={'form__steps'} onSubmit={handleSubmit(onSubmit_end)} noValidate autoComlete="off">
                            <React.Fragment>
                                {activeStep === steps.length ? (
                                    <React.Fragment>
                                        <Box className={'congrats steps_form_congrats'}>
                                            <Box className={'congrats__img'}>&nbsp;</Box>
                                            <Box className={'congrats__inner'}>
                                                <Typography variant="h3" gutterBottom>
                                                    {translate('thankYou')}
                                                </Typography>

                                                <p>
                                                    {translate('notifyCohort')}
                                                </p>
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Stepper activeStep={activeStep} className={'steps-top'}>
                                            {steps.map((label) => (
                                                <Step key={label} className={'steps-name'}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {activeStep === steps.length - 1 ? (
                                                <React.Fragment>
                                                    <Box className={'company__box'}>
                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('haveEmergencyFund')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue=""
                                                                    {...register('HaveAnEmergencyFund', { required: true })}
                                                                    value={personName4}
                                                                    onChange={handleChange4}
                                                                    id="select"
                                                                    className="select"
                                                                >
                                                                    {areas.map((area) => (
                                                                        <MenuItem
                                                                            key={area}
                                                                            value={translate(area)}
                                                                        >
                                                                            {translate(area)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <p className={'mini'}>
                                                                    {translate('fundDescription')}
                                                                </p>
                                                            </FormControl>

                                                            <p className={'error'}>{errors.HaveAnEmergencyFund?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>
                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('haveSavings')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue=""
                                                                    {...register('HaveSavings', { required: true })}
                                                                    value={personName5}
                                                                    onChange={handleChange5}
                                                                    id="select2"
                                                                    className="select"
                                                                >
                                                                    {savings.map((sav) => (
                                                                        <MenuItem
                                                                            key={sav}
                                                                            value={translate(sav)}
                                                                        >
                                                                            {translate(sav)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                            </FormControl>
                                                            <p className={'error'}> {errors.HaveSavings?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>


                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('haveInvested')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue={''}
                                                                    {...register('HaveYouInvesteds', { required: true })}
                                                                    value={personName6}
                                                                    onChange={handleChange6}
                                                                    id="select3"
                                                                    className="select"
                                                                >
                                                                    {years.map((ye) => (
                                                                        <MenuItem
                                                                            key={ye}
                                                                            value={translate(ye)}
                                                                        >
                                                                            {translate(ye)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                            </FormControl>
                                                            <p className={'error'}> {errors.HaveYouInvesteds?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>
                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('willInvest')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue={''}
                                                                    {...register('InvestAtTheEndOfTheProgram', { required: true })}
                                                                    value={personName7}
                                                                    onChange={handleChange7}
                                                                    id="select4"
                                                                    className="select"
                                                                >
                                                                    {abouts.map((about) => (
                                                                        <MenuItem
                                                                            key={about}
                                                                            value={translate(about)}
                                                                        >
                                                                            {translate(about)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                            </FormControl>
                                                            <p className={'error'}> {errors.InvestAtTheEndOfTheProgram?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>
                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('yourProfession')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue=""
                                                                    {...register('WhatDoYouDoForALiving', { required: true })}
                                                                    value={personName8}
                                                                    onChange={handleChange8}
                                                                    id="select5"
                                                                    className="select"
                                                                >
                                                                    {livings.map((liv) => (
                                                                        <MenuItem
                                                                            key={liv}
                                                                            value={translate(liv)}
                                                                        >
                                                                            {translate(liv)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>

                                                            </FormControl>
                                                            <p className={'error'}> {errors.WhatDoYouDoForALiving?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>

                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('professionalExperience')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue=""
                                                                    {...register('YearsOfProfessionalExperience', { required: true })}
                                                                    value={personName9}
                                                                    onChange={handleChange9}
                                                                    id="select6"
                                                                    className="select"
                                                                >
                                                                    {experiences.map((experience) => (
                                                                        <MenuItem
                                                                            key={experience}
                                                                            value={translate(experience)}
                                                                        >
                                                                            {translate(experience)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <p className={'error'}> {errors.YearsOfProfessionalExperience?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>

                                                        <Box sx={{position: 'relative'}}>
                                                            <FormControl className={'select-box'}>
                                                                <InputLabel>
                                                                    {translate('howYouHearAboutUs')}
                                                                </InputLabel>
                                                                <Select
                                                                    defaultValue=""
                                                                    {...register('YouFindOutAboutThisCall', { required: true })}
                                                                    value={personName10}
                                                                    onChange={handleChange10}
                                                                    id="select7"
                                                                    className="select"
                                                                >
                                                                    {aboutin.map((ab) => (
                                                                        <MenuItem
                                                                            key={ab}
                                                                            value={translate(ab)}
                                                                        >
                                                                            {translate(ab)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <p className={'error'}> {errors.YouFindOutAboutThisCall?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>
                                                        <Box sx={{position: 'relative'}}>
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                {...register('why', {required: true, maxLength: 1000})}
                                                                className={'textarea'}
                                                                label=""
                                                                multiline
                                                                rows={4}
                                                                placeholder={translate('whyWantJoin')}
                                                            />
                                                            <p className={'error'}>{errors.why?.type === 'required' && translate('requiredField')}</p>
                                                        </Box>

                                                        <Box className="first-complete-block" sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <Button
                                                                variant="contained"
                                                                onClick={handlePrev}
                                                                sx={{ width: '131px'}}
                                                                className={'btn'}
                                                            >
                                                                {translate('back')}
                                                            </Button>

                                                            <Button
                                                                variant="contained"
                                                                onClick={handleSubmit(onSubmit_end)}
                                                                sx={{ width: '131px'}}
                                                                className={'btn'}
                                                            >
                                                                {translate('send')}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Box className={'company__box'}>
                                                    <Box sx={{position: 'relative'}}>
                                                        <Input className={'input-text'} {...register('firstName', {
                                                            required: true,
                                                            pattern: regExName,
                                                        })} placeholder={translate('firstName')}
                                                        />
                                                        <p className={'error'}> {errors.firstName?.type === 'required' && translate('requiredFirstName')}</p>
                                                        <p className={'error'}> {errors.firstName?.type === 'pattern' && translate('invalidCharacters')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <Input className={'input-text'} {...register('lastName', {
                                                            required: true,
                                                            pattern: regExName,
                                                        })} placeholder={translate('lastName')}
                                                        />
                                                        <p className={'error'}>{errors.lastName?.type === 'required' && translate('requiredLastName')}</p>
                                                        <p className={'error'}> {errors.lastName?.type === 'pattern' && translate('invalidCharacters')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <FormControl className={'select-box'}>
                                                            <InputLabel>{translate('gender')}</InputLabel>
                                                            <Select
                                                                {...register('Gender', { required: true })}
                                                                value={personName}
                                                                onChange={handleChange}
                                                                id="select8"
                                                                className="select"
                                                            >
                                                                {genders.map((gen) => (
                                                                    <MenuItem
                                                                        key={gen}
                                                                        value={translate(gen)}
                                                                    >
                                                                        {translate(gen)}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <p className={'error'}>{errors.Gender?.type === 'required' && translate('requiredGender')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <Input
                                                            className={'input-text'}
                                                            {...register('email', {
                                                                required: true,
                                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            })}
                                                            name="email"
                                                            id="email"
                                                            placeholder={translate('email')}
                                                            autoComplete="email"
                                                            aria-label="email"
                                                            label="email"
                                                        />
                                                        <p className={'error'}>{errors.email?.type === 'required' && translate('emailIsRequired')}</p>
                                                        <p className={'error'}>{errors.email?.type === 'pattern' && translate('emailIsNotValid')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <Input className={'input-text'} {...register('Nationality', {
                                                            required: true,
                                                            pattern: /^(?!.*[\s]{2})[A-Za-zà-úÀ-Úà-úÀ-Úâ-úÀ-Úā-ūĀ-Ū\s_-]+$/i
                                                        })} placeholder={translate('nationality')} />
                                                        <p className={'error'}>{errors.Nationality?.type === 'required' && translate('requiredNationality')}</p>
                                                        <p className={'error'}>{errors.Nationality?.type === 'pattern' && translate('invalidCharacters')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <Input className={'input-text'} {...register('CountryofResidence', {
                                                            required: true,
                                                            pattern: /^(?!.*[\s]{2})[A-Za-zà-úÀ-Úà-úÀ-Úâ-úÀ-Úā-ūĀ-Ū\s_-]+$/i
                                                        })} placeholder={translate('residenceCountry')} />
                                                        <p className={'error'}>{errors.CountryofResidence?.type === 'required' && translate('requiredResidenceCountry')}</p>
                                                        <p className={'error'}>{errors.CountryofResidence?.type === 'pattern' && translate('invalidCharacters')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <FormControl className={'select-box'}>
                                                            <InputLabel>{translate('aegRange')}</InputLabel>
                                                            <Select
                                                                {...register('Age', { required: true })}
                                                                value={personName2}
                                                                onChange={handleChange2}
                                                                id="select9"
                                                                className="select"
                                                            >
                                                                {types.map((type) => (
                                                                    <MenuItem
                                                                        key={type}
                                                                        value={translate(type)}
                                                                    >
                                                                        {translate(type)}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <p className={'error'}>{errors.Age?.type === 'required' && translate('requiredAgeRange')}</p>
                                                    </Box>
                                                    <Box sx={{position: 'relative'}}>
                                                        <FormControl className={'select-box'} hiddenLabel>
                                                            <InputLabel>{translate('doYouHaveHours')}</InputLabel>
                                                            <Select
                                                                className="select"
                                                                defaultValue=""
                                                                {...register('CanYouCommitaTotal', { required: true })}
                                                                id="select10"
                                                                value={personName3}
                                                                onChange={handleChange3}
                                                            >
                                                                {industrys.map((industry) => (
                                                                    <MenuItem
                                                                        key={industry}
                                                                        value={translate(industry)}
                                                                    >
                                                                        {translate(industry)}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                        <p className={'error'}>  {errors.CanYouCommitaTotal?.type === 'required' && translate('requiredField')}</p>
                                                    </Box>
                                                    <Box className="complete-block" sx={{position: 'relative', display: 'flex', justifyContent: 'space-between'}}>
                                                        <Box className={'checkbox'} sx={{width: '325px'}}>
                                                            <Checkbox
                                                                sx={{
                                                                    '&:hover': {bgcolor: 'transparent'},
                                                                    paddingLeft: 0, paddingTop: 0, paddingBottom: 0
                                                                }}
                                                                disableRipple
                                                                color="default"
                                                                checked={checkboxValue}
                                                                checkedIcon={<BpCheckedIcon/>}
                                                                icon={<BpIcon/>}
                                                                inputProps={{'aria-label': 'Checkbox demo'}}
                                                                onClick={handleChangeCheckbox}
                                                                {...register('termsAndConditions', {required: true})}
                                                            />

                                                            <InputLabel>
                                                                {translate('acceptTermsCheckboxFirstPart')}
                                                                <a
                                                                    href={'https://unicornhunters.com/terms/'}
                                                                    target="_blank"
                                                                    rel={'noreferrer'}
                                                                >
                                                                    {translate('termsAndConditions')}
                                                                </a>
                                                                
                                                                {translate('acceptTermsCheckboxSecondtPart')}
                                                                
                                                                <a
                                                                    href={'https://unicornhunters.com/privacy-policy/'}
                                                                    target="_blank"
                                                                    rel={'noreferrer'}
                                                                >
                                                                    {translate('privacyPolicy')}
                                                                </a>
                                                                .
                                                            </InputLabel>

                                                            <p className={'error'}> {errors.termsAndConditions?.type === 'required' && translate('requiredTerms')}</p>
                                                        </Box>

                                                        <Button
                                                            variant="contained"
                                                            onClick={handleSubmit(onSubmit)}
                                                            sx={{ width: '154px'}}
                                                            className={'btn next'}
                                                        >
                                                            {translate('next')}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </Box>
                    </GoogleReCaptchaProvider>
                </Box>
            </Box>
        </Box>
    )
};