import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './command.scss';
import command1 from '../../img/command/command1.jpg';
import command2 from '../../img/command/command2.jpg';
import command3 from '../../img/command/command3.jpg';
import command4 from '../../img/command/command4.jpg';

export default function Command() {
    const { t: translate } = useTranslation('home');

    return (
        <Box className={'command__box'} id={'command'}>
            <h2>
                {translate('teamTitle')}
            </h2>
            <Grid container direction="row" className={'command__grid'}
                  justifyContent="center" alignItems="flex-start">
                <Box className={'command__item'}>
                    <img src={command1} alt={''}/>
                    <Box className={'text'}>
                        <h4>
                            Silvina Moschini
                        </h4>
                        <p><strong>{translate('person1')}</strong>
                            Unicorn Hunters     </p>
                    </Box>
                </Box>
                <Box className={'command__item'}>
                    <img src={command2} alt={''}/>
                    <Box className={'text'}>
                        <h4>
                            Carla Cassanello
                        </h4>
                        <p><strong>{translate('person2')}</strong>
                            Unicorn Hunters</p>
                    </Box>
                </Box>
                <Box className={'command__item'}>
                    <img src={command3} alt={''}/>
                    <Box className={'text'}>
                        <h4>
                            Liesl Hros Isler
                        </h4>
                        <p><strong>{translate('person3')}</strong>
                            Unicorn Hunters
                        </p>
                    </Box>
                </Box>
                <Box className={'command__item'}>
                    <img src={command4} alt={''}/>
                    <Box className={'text'}>
                        <h4>
                            Jessica Olivan
                        </h4>
                        <p><strong>{translate('person4')}</strong>
                            Unicorn Hunters
                        </p>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}