import * as React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Clear from "@mui/icons-material/Clear";
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    bgcolor: 'none',
    border: 'none',
    borderRadius: '10px',
    boxShadow: 24,
    p: 0,
};

export default function Modals (props) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Modal
            open={open && props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                <Box className={'congrats contact'}>
                    <Button className={'close'} onClick={handleClose}>
                        <Clear sx={{color:"#000"}}/>
                    </Button>
                    <Box className={'congrats__inner'}>
                        <Box className={'congrats__img'}>&nbsp;</Box>
                        <p>{props.text}</p>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

Modals.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
