import * as React from 'react';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import {
    Box,
    ClickAwayListener,
    Grid,
    IconButton,
    Popper,
} from '@mui/material';

import { ReactComponent as MenuIcon } from 'img/header_menu.svg';
import logo from 'img/logo.png';

import AccordionSelectLanguage from './AccordionSelectLanguage';
import SelectLanguage from './SelectLanguage';

import './header.scss';

export default function Header(){
    const { t: translate, i18n } = useTranslation('home');

    const isEnglish = i18n.language === 'en';
    const { lng } = useParams();

    const [currentLanguage, setCurrentLanguage] = React.useState(i18n.language);
    const [expanded, setExpanded] = React.useState(false);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(prev => !prev ? event.currentTarget : false);
    };

    const handleClickAway = () => {
        setAnchorElNav(null);
    };

    const handleExpanded = () => {
        setExpanded(prev => !prev);
    };

    const handleChangeLanguage = (event) => {
        const lang = event.target.value;

        setCurrentLanguage(lang);
        i18n.changeLanguage(lang);
        setAnchorElNav(null);
        handleExpanded();
    };

    const scrollToDiv = id =>{
        let path = window.location.pathname;
        let ids = id;

        const divElement = document.getElementById(id);

        if (path === `/${lng}` || path === `/${lng}/` || path === `/`) {
            divElement.scrollIntoView({ behavior: 'smooth' });
            setAnchorElNav(null);
        } else {
            window.location = (isEnglish ? `/?` : `/${lng}/?`) + ids ;
            setAnchorElNav(null);
        }
    }

    return(
        <Box sx={{maxWidth: '1170px', margin: '0 auto'}} className={'header'}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs="auto">
                    <h1>
                        <a href={isEnglish ? '/' : `/${lng}`}>
                            <img className={"logo"} src={logo} alt={'s4s'}/>
                        </a>
                    </h1>
                </Grid>
                <Grid item xs="auto" className={'header__menu'} sx={{display: { xs: 'flex', md: 'none' }}}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                        <IconButton
                            size="large"
                            className={'header__menu-icon'}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Popper
                            className="menu__box menu_popper"
                            disablePortal
                            keepMounted
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            open={Boolean(anchorElNav)}
                            placement="bottom-end"
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <Link
                                className={"menu__link"}
                                onClick={() => scrollToDiv('form')}
                                to={isEnglish ? `/` : `/${lng}`}
                            >
                                {translate('headerLinksSignUp')}
                            </Link>

                            <Link
                                className={"menu__link"}
                                onClick={() => scrollToDiv('about')}
                                to={isEnglish ? `/` : `/${lng}`}
                            >
                                {translate('headerLinksAbout')}
                            </Link>

                            <Link
                                className={"menu__link"}
                                onClick={() => scrollToDiv('command')}
                                to={isEnglish ? `/` : `/${lng}`}
                            >
                                {translate('headerLinksOurTeam')}
                            </Link>

                            <Link
                                className={"menu__link"}
                                onClick={() => scrollToDiv('faq')}
                                to={isEnglish ? `/` : `/${lng}`}
                            >
                                {translate('headerLinksFAQ')}
                            </Link>

                            <Link className={"menu__link"} to={isEnglish ? `/contact` : `/${lng}/contact`}>
                                {translate('headerLinksContact')}
                            </Link>

                            <AccordionSelectLanguage
                                currentLanguage={currentLanguage}
                                expanded={expanded}
                                handleChange={handleChangeLanguage}
                                handleExpanded={handleExpanded}
                            />
                        </Popper>
                        </div>
                    </ClickAwayListener>
                </Grid>

                <Grid item xl={9} lg={9} sm={9} className={'header__menu'} sx={{display: { xs: 'none', md: 'block' }}}>
                    <Box
                        className={"menu__box"}
                    >
                        <Link
                            className={"menu__link"}
                            onClick={() => scrollToDiv('form')}
                            to={isEnglish ? `/` : `/${lng}`}
                        >
                            {translate('headerLinksSignUp')}
                        </Link>

                        <Link
                            className={"menu__link"}
                            onClick={() => scrollToDiv('about')}
                            to={isEnglish ? `/` : `/${lng}`}
                        >
                            {translate('headerLinksAbout')}
                        </Link>

                        <Link
                            className={"menu__link"}
                            onClick={() => scrollToDiv('command')}
                            to={isEnglish ? `/` : `/${lng}`}
                        >
                            {translate('headerLinksOurTeam')}
                        </Link>

                        <Link
                            className={"menu__link"}
                            onClick={() => scrollToDiv('faq')}
                            to={isEnglish ? `/` : `/${lng}`}
                        >
                            {translate('headerLinksFAQ')}
                        </Link>

                        <Link className={"menu__link"} to={isEnglish ? `/contact` : `/${lng}/contact`}>
                            {translate('headerLinksContact')}
                        </Link>

                        <SelectLanguage
                            classes={{
                                select: 'selectLanguage',
                                menu: 'selectLanguageMenu',
                            }}
                            currentLanguage={currentLanguage}
                            handleChange={handleChangeLanguage}
                        />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
};