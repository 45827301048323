import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';

import './faq.scss';

const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    border: 0,
    '&:not(:last-child)': {
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: '2px solid',
        borderImageSource: 'linear-gradient(to right, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1)',
        borderImageSlice: 1,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '18px' }} />}
        {...props}
    />
))(({ theme }) => ({
    maxHeight: '72px',
    border: 0,
    padding: 0,
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(255, 255, 255, 1)',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'none',

        [theme.breakpoints.down(380)]: {
            paddingLeft: 10,
        },
    },
    '& .MuiAccordionSummary-expandIconWrapper svg': {
        display: 'none',
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
        [theme.breakpoints.down(380)]: {
            paddingLeft: 10,
        },
    },

    '& .MuiAccordionSummary-content': {
        marginLeft: 0,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: 0,
    borderTop: 0,
}));

export default function Faq() {
    const { t: translate, i18n } = useTranslation('home');

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (_, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box className={'faq__box'} id={'faq'}>
            <Box className={'faq__left'}>
                <h2 className={i18n.language === 'en' ? 'title_en' : ''}>{translate('faqs')}</h2>
                <Button className={"btn"} onClick={() => scrollToDiv('form')} variant="contained">
                    {translate('signUp')}
                </Button>
            </Box>

            <Box className={'faq__accordion'}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <p><strong>{translate('faq1')}</strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer1')}
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <p><strong>{translate('faq2')}</strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer2')}
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <p><strong>{translate('faq3')}</strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer3')}
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <p><strong>{translate('faq4')}</strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer4')}
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <p><strong>
                            {translate('faq5')}
                        </strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer5')}
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                        <p><strong>
                            {translate('faq6')}
                        </strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer6')}
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                        <p><strong>{translate('faq7')}</strong></p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            {translate('faqAnswer7')}
                            <b>{translate('schoolOfInvestors')}.</b>
                        </p>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}
