import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import singularitySrc from 'img/logos/singularity.png';
import cibersons from 'img/logos/cibersons.png';

import logoMain from '../../img/logos/logo-main.png';
import  Logo1 from '../../img/logos/logo1.png';
import  Logo2 from '../../img/logos/logo2.png';
import  Logo3 from '../../img/logos/logo3.png';
import  Logo4 from '../../img/logos/logo4.png';
import  Logo5 from '../../img/logos/logo5.png';
import  Logo6 from '../../img/logos/logo6.png';
import  Logo7 from '../../img/logos/logo7.png';

import './logos.scss';

export default function Logos() {
    const { t: translate } = useTranslation('home');

    return (
        <Box className={'logos__box'} id={'sliderLogo'}>
            <h3>
                {translate('logosFirst')}
            </h3>

            <img src={logoMain} alt={''} className={'first'}/>

            <h3>
                {translate('logosSecond')}
            </h3>

            <Box className="secondBox">
                <img alt={''} src={singularitySrc} />

                <img alt={''} src={cibersons} />
            </Box>

            <h3 className="thirdHeader">
                {translate('logosThird')}
            </h3>

            <Box className={'logos__list'}>
                <img src={Logo1} alt={''}/>
                <img src={Logo2} alt={''}/>
                <img src={Logo3} alt={''}/>
                <img src={Logo4} alt={''}/>
                <img src={Logo5} alt={''}/>
                <img src={Logo6} alt={''}/>
                <img src={Logo7} alt={''}/>
            </Box>

        </Box>
    )

};