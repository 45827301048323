import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useForm} from "react-hook-form";
import Input from "@mui/material/Input";
import './footer.scss';
import { ReactComponent as Ln } from '../../img/linkedin.svg';
import simpleFetch from "very-simple-fetch";
import Modals from "../modal";


export default function Footer() {
    const { t: translate } = useTranslation('home');

    const { register, handleSubmit, formState: { errors }} = useForm();
    const [open, setOpen] = React.useState(false);
    const onSubmit_end = async (data) => {
        const respon = simpleFetch({
           url: global.config.webhook_news,
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers({'Content-Type':'application/json'}),
            body: JSON.stringify(data),
        }).then(() => {
            setOpen(true);
            document.getElementById('form__news').reset();
        })
            .catch((error) => {
                console.log('error:');
                console.log(error);
            });
        if(respon){
            console.log(respon)
        }

    };

    return (
        <Box className={'footer__box'}>
            <Box className={'container'}>
                <Box className={'left'}>
                    <a href="https://www.linkedin.com/groups/12650391/"  aria-label="linkedin" target="_blank" rel="noreferrer">
                        <Ln width="25"/>
                    </a>
                    <p>2022 © Copyright Escuela de Inversoras</p>
                </Box>

                <Box className={'right'}>
                    <h3>{translate('footerSubscribe')}</h3>
                    <form onSubmit={handleSubmit(onSubmit_end)} id={'form__news'} noValidate>
                        <Box sx={{position: 'relative'}}>
                            <Box sx={{position: 'relative'}}>
                                <Input
                                    className={'input-text'}
                                    {...register('email', {
                                        required: true,
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                    placeholder={translate('emailField')}
                                />
                                
                                <p className={'error'}>{errors.email?.type === 'required' && translate('emailIsRequired')}</p>
                                <p className={'error'}>{errors.email?.type === 'pattern' && translate('emailIsNotValid')}</p>
                            </Box>
                            <Button className={"btn"} onClick={handleSubmit(onSubmit_end)} variant="contained">
                                {translate('send')}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>

            <Modals open={open} text={translate('thanksForSubscribing')} />
        </Box>
    )
};