import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './about.scss';
import about from '../../img/about/about1.png';
import about2 from '../../img/about/about2.svg';
import about3 from '../../img/about/about3.svg';

export default function About() {
    const { t: translate } = useTranslation('home');

    return (
        <Box className={'about__box'} id={'about'}>
            <Box className={'container'}>
                <h2>
                    {translate('about')}
                </h2>
                <Grid container direction="row" className={'about__grid'}
                      justifyContent="space-between" alignItems="flex-start">
                    <Box className={'about__item'}>
                        <Box className={'about__item-img'}>
                            <img src={about} alt={''}/>
                        </Box>
                        <h4>{translate('aboutClasses')}</h4>
                        <p>
                            {translate('aboutClassesDescription')}
                        </p>
                    </Box>
                    <Box className={'about__item'}>
                        <Box className={'about__item-img'}>
                            <img src={about2} alt={''}/>
                        </Box>
                        <h4>
                            {translate('aboutContent')}
                        </h4>
                        <p>
                            {translate('aboutContentDescription')}
                        </p>
                    </Box>
                    <Box className={'about__item'}>
                        <Box className={'about__item-img'}>
                            <img src={about3} alt={''}/>
                        </Box>
                        <h4>{translate('aboutExclusive')}</h4>
                        <p>
                            {translate('aboutExclusiveDescription')}
                        </p>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}