import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './header.scss';

const LANGUAGES = [
  {
    code: 'en',
    name: 'ENGLISH'
  },
  {
    code: 'es',
    name: 'ESPAÑOL',
  },
];

const AccordionSelectLanguage = ({
  classes,
  currentLanguage,
  expanded,
  handleChange,
  handleExpanded,
}) => {
  return (
    <Accordion
      className={`languageAccordion ${classes?.select}`}
      expanded={expanded}
      onChange={handleExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <span>
          {currentLanguage.toUpperCase()}
        </span>
      </AccordionSummary>

      <AccordionDetails>
        {LANGUAGES.map((item, index) => (
          <button
            className="menuItems"
            key={`${item.name}_${index}`}
            value={item.code}
            onClick={handleChange}
          >
            {item.name}
          </button>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(AccordionSelectLanguage);

AccordionSelectLanguage.propTypes = {
  classes: PropTypes.shape({
    select: PropTypes.string,
    menu: PropTypes.string,
  }),
  currentLanguage: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleExpanded: PropTypes.func.isRequired,
}
