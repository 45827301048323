import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import './pink.scss';

export default function Pink() {
    const { t: translate } = useTranslation('home');

    return (
        <Box className={'pink__box'}>
            <h2>
                {translate('pinkQuote')}
            </h2>

            <h3>
                SILVINA MOSCHINI
            </h3>

            <p>
                {translate('pinkAuthor')}
            </p>
        </Box>
    )
}